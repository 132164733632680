<template>
  <div>
    <b-row cols="2">
      <b-col
        cols="12"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        id="gcp-services-table-card-col"
      >
        <b-card class="card-height h-100" id="gcp-services-table-card">
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <!-- table -->
          <b-table
            hover
            v-show="!loading"
            v-if="gcpSpendResponse !== ''"
            :items="tableItems"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            empty-text="No matching records found"
            id="gcp-services"
            class="mt-1"
            @head-clicked="onHeadClicked"
            responsive
          >
            <template #head()="data">
              <span v-if="data.label == 'Services'">
                {{ data.label }}
              </span>
              <span v-else class="d-flex justify-content-center">{{
                data.label
              }}</span>
            </template>

            <template #cell(services)="data">
              <div class="row d-flex justify-content-start">
                <b-form-checkbox
                  v-model="data.item.isChecked"
                  @change="checked(data.item)"
                >
                </b-form-checkbox>
                <span
                  v-b-tooltip.hover
                  :title="data.item.services"
                  id="circle"
                  :style="{ backgroundColor: data.item.color }"
                  v-if="data.item.services"
                >
                </span>
                <span v-b-tooltip.hover :title="data.item.services">
                  {{
                    data.item.services.length > 20
                      ? data.item.services.substring(0, 20) + "..."
                      : data.item.services
                  }}
                </span>
              </div>
            </template>
            <template #cell(cost)="data">
              <span class="d-flex justify-content-center">{{
                data.item.cost | toCurrency(data.item.currency)
              }}</span>
            </template>
            <template #cell(discounts)="data">
              <span class="d-flex justify-content-center">
                {{ data.item.discounts | toCurrency(data.item.currency) }}
              </span>
            </template>
            <template #cell(total)="data">
              <span class="d-flex justify-content-center">
                {{ data.item.total | toCurrency(data.item.currency) }}</span
              >
            </template>
          </b-table>
          <!-- total -->
          <b-card v-show="!loading" class="border-top border-bottom">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                lg="8"
                md="8"
                sm="8"
                xs="8"
                v-show="!loading"
                class="d-flex align-items-center justify-content-start"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  first-number
                  last-number
                  limit="3"
                  prev-class="prev-item"
                  next-class="next-item"
                  aria-controls="my-table"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>

              <b-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
                xs="4"
                class="d-flex align-items-center justify-content-end"
                style="font-weight: bold"
                v-if="totalCost"
              >
                Total:
                {{ totalCost.total | toCurrency(totalCost.currency) }}
              </b-col>
            </b-row>
          </b-card>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6" md="12" sm="12" xs="12" id="linear-chart-view">
        <b-card class="card-height h-100">
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <!-- chart -->
          <LineChartGenerator
            v-if="gcpSpendResponse"
            v-show="!loading"
            :chart-options="chartOptions"
            :chart-data="chartData"
            responsive="true"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BDropdownDivider,
  BTable,
  BPagination,
  BSpinner,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "UsaGcpSpend",
  components: {
    BCard,
    Loading,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BDropdownDivider,
    BTable,
    BPagination,
    BSpinner,
    LineChartGenerator,
    BFormCheckbox,
  },
  props: {
    monthDays: {
      type: Array,
    },
    gcpSpendResponse: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    currency: {
      type: String,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      isFullScreen: false,
      showClearButton: false,
      checkedServices: [],
      finalChartData: {
        labels: [],
        datasets: [],
      },
      tableColumns: [
        { key: "services", sortable: true },
        { key: "cost", sortable: true },
        { key: "discounts", sortable: true },
        { key: "total", sortable: true },
      ],
      sortBy: "",
      isSortDirDesc: true,
      totalCost: 0,
      tableItems: [],
      currentPage: 1,
      perPage: 10,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },

        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            time: {
              unit: "day",
            },
            grid: {
              tickColor: "white",
            },
            title: {
              display: true,
              text: "Dates of month",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {},
          },
          y: {
            grid: {
              tickColor: "white",
            },
            title: {
              display: true,
              text: "Cost",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {
              callback: function (value) {
                if (store.state.app.currencyType == "USD") {
                  return "$ " + value;
                } else if (store.state.app.currencyType == "GBP") {
                  return "£ " + value;
                } else if (store.state.app.currencyType == "EUR") {
                  return "€ " + value;
                }
              },
            },
          },
        },
      },
    };
  },
  beforeMount() {
    if (store.state.appConfig.layout.skin == "dark") {
      this.chartOptions.scales.x.title.color = "#FFFFFF";
      this.chartOptions.scales.y.title.color = "#FFFFFF";
      this.chartOptions.scales.x.ticks.color = "#D0D2D6";
      this.chartOptions.scales.y.ticks.color = "#D0D2D6";
    } else {
      this.chartOptions.scales.x.title.color = "#5e5873";
      this.chartOptions.scales.y.title.color = "#5e5873";
      this.chartOptions.scales.x.ticks.color = "#6E6B7B";
      this.chartOptions.scales.y.ticks.color = "#6E6B7B";
    }
  },
  watch: {
    gcpSpendResponse: function () {
      this.getCurrentMonth();
      this.getUniqueServicesDescription();
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.currentPage = 1;
      },
    },
    "$store.state.appConfig.layout.skin": {
      handler() {
        if (store.state.appConfig.layout.skin == "dark") {
          this.chartOptions.scales.x.title.color = "#FFFFFF";
          this.chartOptions.scales.y.title.color = "#FFFFFF";
          this.chartOptions.scales.x.ticks.color = "#D0D2D6";
          this.chartOptions.scales.y.ticks.color = "#D0D2D6";
        } else {
          this.chartOptions.scales.x.title.color = "#5e5873";
          this.chartOptions.scales.y.title.color = "#5e5873";
          this.chartOptions.scales.x.ticks.color = "#6E6B7B";
          this.chartOptions.scales.y.ticks.color = "#6E6B7B";
        }
      },
      immediate: false,
    },
  },
  methods: {
    // selectAllRows() {
    //  this.$refs.selectableTable.selectAllRows()
    //  this.chartData.datasets = this.finalChartData.datasets
    // },
    fullScreenView() {
      let elem = document.getElementById("linear-chart-view");
      document.getElementById("line-chart").style.display = "";
      /* Function to open fullscreen mode */
      this.isFullScreen = true;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    closeScreenView() {
      /* Function to open fullscreen mode */
      document.getElementById("line-chart").style.display = "block";
      document.getElementById("line-chart").style.height = "500px";

      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitRequestFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
    clearSelected() {
      for (const [key, value] of Object.entries(this.tableItems)) {
        if (value.isChecked) {
          value.isChecked = false;
          this.showClearButton = false;
          this.checkedServices = [];
          this.currentPage = 1;
          this.chartData.datasets = this.finalChartData.datasets;
        }
      }
    },
    checked(item) {
      if (item.isChecked) {
        for (const [key, value] of Object.entries(
          this.finalChartData.datasets
        )) {
          if (item.services === value.label) {
            this.showClearButton = true;
            this.checkedServices.push(value);
          }
        }
        this.chartData.datasets = this.checkedServices;
      } else {
        if (this.checkedServices.length > 1) {
          for (const [key, value] of Object.entries(this.checkedServices)) {
            if (item.services === value.label) {
              this.checkedServices.splice(key, 1);
            }
          }
        } else {
          this.showClearButton = false;
          this.checkedServices = [];
          this.chartData.datasets = this.finalChartData.datasets;
        }
      }
    },
    onHeadClicked() {
      this.chartData.datasets = this.finalChartData.datasets;
    },
    // onRowClicked(item) {
    //   var checkedServices = [];
    //   for (const [key, value] of Object.entries(this.finalChartData.datasets)) {
    //     if (item.services.name === value.label) {
    //       checkedServices.push(value);
    //     }
    //   }
    //   this.chartData.datasets = checkedServices;
    // },
    getCurrentMonth() {
      this.chartData = {
        ...this.chartData,
        labels: this.monthDays,
      };
    },
    // random_hex_color_code() {
    //   let n = (Math.random() * 0xfffff * 1000000).toString(16);
    //   return "#" + n.slice(0, 6);
    // },
    getUniqueServicesDescription() {
      if (this.gcpSpendResponse.data) {
        this.chartData.datasets = [];
        this.finalChartData.datasets = [];
        this.tableItems = [];
        this.totalCost = 0;

        this.chartData.datasets =
          this.gcpSpendResponse.data.multi_line_data.datasets;
        this.finalChartData.datasets =
          this.gcpSpendResponse.data.multi_line_data.datasets;

        this.gcpSpendResponse.data.services_table_data.sort(function (a, b) {
          return parseFloat(b.total.total) - parseFloat(a.total.total);
        });

        this.tableItems = this.gcpSpendResponse.data.services_table_data;
        this.totalCost = this.gcpSpendResponse.data.total;
      }
    },
  },
};
</script>

<style>
#gcp-services-table-card {
  margin-bottom: 20px;
}

#gcp-services-table-card-col {
  padding-bottom: 2rem;
}

.card-height {
  min-height: 400px;
  max-height: 100%;
}

#linear-chart-view {
  padding-bottom: 2rem;
}

#circle {
  width: 13px;
  height: 13px;
  border-radius: 7px;
  margin: 3px 12px 0px 14px;
}

#line-chart {
  min-height: 40rem;
  max-height: 50rem;
  height: 100%;
}

#bar-chart {
  min-height: 40rem;
  max-height: 50rem;
  height: 100%;
}

#gcp-services-table-card > div > div.card.bg-light {
  margin-bottom: 0 !important;
}

.table-hover tbody tr {
  cursor: default;
}

.table td {
  padding: 0.72rem 1rem;
}

.fullscreen {
  cursor: pointer;
}
#gcp-services > thead > tr > th:nth-child(1) {
  padding: 10.08px 65px;
}
</style>
