<template>
  <section id="dashboard-usa">
    <b-row>
      <b-col cols="12" lg="12" v-if="!closePermanentBanner">
        <usa-banner
          :closeBanner="closePermanentBanner"
          :backgroundColor="backgroundColor"
          routerUrl=""
          :title="title"
          text="If you are about to start a new project and create one in GCP, please
          get with your Account Manager at WALT Labs who can assist with
          possible discounts and/or funding for your project."
          @close-banner-popup="closePermanentBannerPopup"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="12" v-if="!closeBanner">
        <usa-banner
          :closeBanner="closeBanner"
          :backgroundColor="backgroundColor"
          :routerUrl="routerUrl"
          :title="title"
          :text="text"
          @close-banner-popup="closeBannerPopup"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        v-if="
          userData.role_name === 'ORG_ADMIN' ||
          userData.role_name === 'EMPLOYEE'
        "
      >
        <usa-account-manager />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          class="card-height"
          icon="PackageIcon"
          :statistic="gcpSpendByMonth.total | toCurrency(currency)"
          statistic-title="Current MTD GCP Spend"
          :chart-data="gcpSpendByMonth.series"
          :loading="!gcpSpendByMonth.total && loading"
        />
      </b-col>

      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          class="card-height"
          icon="PackageIcon"
          color="warning"
          :statistic="gcpSpendByMonth.forcasted | toCurrency(currency)"
          statistic-title="Forcasted GCP Spend"
          :chart-data="gcpSpendByMonth.series1"
          :loading="!gcpSpendByMonth.forcasted && loading"
        />
      </b-col>

      <b-col lg="6" md="12">
        <usa-congratulation class="card-height" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <usa-date-filter
          @getCurrentMonth="getCurrentMonth"
          @getUniqueServicesDescription="getUniqueServicesDescription"
          @getUniqueProject="getUniqueProject"
          @loadingSpinner="loadingSpinner"
          @getCurrency="getCurrency"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <usa-daily-gcp-spend-bar
          :monthDays="monthDays"
          :projectResponse="projectResponse"
          :gcpSpendResponse="gcpSpendResponse"
          :loading="loading"
          :currency="currency"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <usa-gcp-spend
          :monthDays="monthDays"
          :gcpSpendResponse="gcpSpendResponse"
          :loading="loading"
          :currency="currency"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import axios from "@axios";
import { ref } from "@vue/composition-api";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import UsaAccountManager from "./UsaAccountManager.vue";
import UsaCongratulation from "./UsaCongratulation.vue";
import UsaGcpSpend from "./UsaGcpSpend.vue";
import UsaDailyGcpSpendBar from "./UsaDailyGcpSpendBar.vue";
import UsaDateFilter from "./UsaDateFilter.vue";
import UsaBanner from "./UsaBanner.vue";

export default {
  components: {
    BRow,
    BCol,
    UsaAccountManager,
    UsaCongratulation,
    StatisticCardWithAreaChart,
    UsaGcpSpend,
    UsaDailyGcpSpendBar,
    UsaDateFilter,

    UsaBanner,
  },
  data() {
    return {
      loading: true,
      monthDays: [],
      gcpSpendResponse: {},
      projectResponse: {},
      data: {},
      closeBanner: false,
      closePermanentBanner: false,
      title: "ANNOUNCEMENT",
      routerUrl: "app-scoutsuite",
      backgroundColor: "#4285f4",
      text: "New Feature: GCP Environment Security Scanning.",
    };
  },
  methods: {
    closeBannerPopup(value) {
      this.closeBanner = value;
    },
    closePermanentBannerPopup(value) {
      this.closePermanentBanner = value;
    },
    getCurrentMonth(value) {
      this.monthDays = value;
    },
    getUniqueServicesDescription(value) {
      this.gcpSpendResponse = value;
    },
    getUniqueProject(value) {
      this.projectResponse = value;
    },
    loadingSpinner(value) {
      this.loading = value;
    },
    getCurrency(value) {
      this.currency = value;
    },
    kFormatter,
  },
  beforeMount() {
    this.currentMonthStat();
  },
  watch: {
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.currentMonthStat();
      },
      immediate: false,
    },
  },
  setup() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let totalForcastedDays = ref([]);
    let totalSpendByDay = ref([]);
    let totalForcasted = ref(0);
    let subTotalForcasted = ref(0);
    let currency = ref("");
    let gcpSpendByMonth = ref({});

    const currentMonthStat = () => {
      userData = JSON.parse(localStorage.getItem("userData"));
      const paramObj = {
        currency:
          userData.default_region_id == "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? userData.deafult_currency_code
            : "USD",
      };

      axios
        .get(`/gcp-spend/current_month_stats/${userData.default_region_id}`, {
          params: paramObj,
        })
        .then((response) => {
          if (response) {
            totalForcastedDays.value = [];
            totalSpendByDay.value = [];
            totalForcasted.value = 0;
            subTotalForcasted.value = 0;

            for (const [key, value] of Object.entries(
              response.data.total_spend_by_day
            )) {
              totalForcastedDays.value.push(value.toFixed(2));
              totalSpendByDay.value.push(value.toFixed(2));
              totalForcasted.value = totalForcasted.value + value;
            }
            subTotalForcasted.value =
              totalForcasted.value /
              Object.keys(response.data.total_spend_by_day).length;

            for (const [key, value] of Object.entries(
              Array(
                31 - Object.keys(response.data.total_spend_by_day).length
              ).fill(subTotalForcasted.value)
            )) {
              totalForcastedDays.value.push(value.toFixed(2));
            }

            currency.value = response.data.monthly_total.currency;

            gcpSpendByMonth.value = {
              series: [
                {
                  name: "Gcp",
                  data: totalSpendByDay.value,
                },
              ],
              series1: [
                {
                  name: "Gcp",
                  data: totalForcastedDays.value,
                },
              ],
              total: response.data.monthly_total.total
                ? response.data.monthly_total.total
                : 0,
              forcasted: response.data.monthly_forecast.total
                ? response.data.monthly_forecast.total.toFixed(2)
                : 0,
            };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      userData,
      currentMonthStat,
      totalForcastedDays,
      totalSpendByDay,
      totalForcasted,
      subTotalForcasted,
      currency,
      gcpSpendByMonth,
    };
  },
};
</script>

<style>
.card-height {
  min-height: 26rem !important;
}
</style>
