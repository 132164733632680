<template>
  <div>
    <b-card class="filter-body">
      <b-row>
        <b-col
          lg="3"
          md="3"
          sm="12"
          xs="12"
          class="p-1"
          v-if="
            userData.role_name == 'GLOBAL_ADMIN' ||
            userData.role_name == 'ACCOUNT_MANAGER'
          "
        >
          <v-select
            v-model="selectedOrganization"
            :options="organization"
            placeholder="Organization"
            label="name"
            @input="selectBillingIdFromOrganization"
            :disabled="
              selectedBillingAccountId.length > 0 &&
              selectedOrganization !== null
            "
          >
            <span slot="no-options">{{ noOptionMessage }}</span>
          </v-select>
        </b-col>

        <b-col lg="3" md="3" sm="12" xs="12" class="p-1">
          <v-select
            multiple
            v-model="selectedBillingAccountId"
            :options="billingAccountIds"
            placeholder="Billing Account Id"
            @input="selectBillingId"
            v-if="userData.role_name !== 'EMPLOYEE'"
          >
            <span slot="no-options">{{ noOptionMessage }}</span>
          </v-select>
        </b-col>

        <b-col lg="3" md="3" sm="12" xs="12" class="p-1">
          <v-select
            multiple
            v-model="projectSelected"
            :options="projectOptions"
            placeholder="All Projects"
          >
            <span slot="no-options">{{ noOptionMessage }}</span>
          </v-select>
        </b-col>

        <b-col lg="3" md="4" sm="12" xs="12" class="p-1">
          <date-picker
            v-model="dateRange"
            lang="en"
            type="date"
            range
            format="MMMM DD, YYYY"
            placeholder="Select Date Range"
            range-separator=" - "
            class="custom-datepicker-filter"
            calendar-class="my-datepicker_calendar"
          >
          </date-picker>
        </b-col>

        <b-col
          lg="3"
          md="3"
          sm="12"
          xs="12"
          class="p-1"
          v-if="
            userData.default_region_id ==
              'f06c8247-3b89-4ab5-8bb7-3734bf931c53' &&
            (userData.role_name == 'GLOBAL_ADMIN' ||
              userData.role_name == 'ACCOUNT_MANAGER')
          "
        >
          <b-form-select
            v-model="selectedCurrency"
            :options="currencyOptions"
            value-field="code"
            text-field="code"
          />
        </b-col>

        <b-col lg="3" md="12" sm="12" xs="12" class="p-1">
          <b-button variant="primary" class="mr-2" @click="filterDate">
            <div class="d-flex align-items-center">
              <b-spinner
                small
                label="Text Centered"
                v-if="isLoading"
              ></b-spinner>
              <span class="button-title">Filter</span>
            </div>
          </b-button>

          <b-button
            variant="primary"
            @click="clear"
            :disabled="
              projectSelected == '' &&
              selectedBillingAccountId == '' &&
              selectedOrganization == null &&
              dateRange == ''
            "
          >
            <div class="d-flex align-items-center">
              <b-spinner small label="Text Centered" v-if="isLoad"></b-spinner>
              <span class="button-title">Clear</span>
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker/index.esm";
import "vue2-datepicker/index.css";
import {
  BCard,
  BFormSelect,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import store from "@/store";
import axios from "@axios";
import vSelect from "vue-select";
export default {
  components: {
    DatePicker,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      dateRange: [],
      projectSelected: [],
      projectOptions: [],
      billingAccountIds: [],
      organization: [],
      selectedBillingAccountId: [],
      selectedOrganization: null,
      selectedCurrency: null,
      currencyOptions: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      loading: false,
      startDate: "",
      endDate: "",
      // minEnd: "",
      options: ["ana", "bal", "cal"],
      // maxEnd: "",
      currentMothDays: [],
      selectedDates: [],
      isLoading: false,
      isLoad: false,
      noOptionMessage: "Sorry, data is loading",
    };
  },
  created() {
    if (
      this.userData.default_region_id == "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
    ) {
      const regionCurrencyData = JSON.parse(
        localStorage.getItem("regionCurrencyData")
      );
      for (const [key, value] of Object.entries(
        regionCurrencyData.regions_details
      )) {
        this.currencyOptions = value.currencies;
      }
    }
    this.selectedCurrency = this.userData.deafult_currency_code;
    store.commit("app/CURRENCY_TYPE", this.userData.deafult_currency_code);
  },
  watch: {
    selectedCurrency(newValue) {
      if (newValue) {
        store.commit("app/CURRENCY_TYPE", newValue);
      }
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.projectSelected = "";
        this.selectedBillingAccountId = "";
        this.selectedOrganization = null;
        this.dateRange = "";
        this.dateRange = [];
        this.selectedCurrency = this.userData.deafult_currency_code;
        if (
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
        ) {
          const regionCurrencyData = JSON.parse(
            localStorage.getItem("regionCurrencyData")
          );
          for (const [key, value] of Object.entries(
            regionCurrencyData.regions_details
          )) {
            this.currencyOptions = value.currencies;
          }
        }
        this.getCurrentMonth();
        this.getBillingAccountIds();
        this.getOrganization();
        this.getUniqueProject();
        this.getUniqueServicesDescription();
      },
      immediate: false,
    },
  },
  beforeMount() {
    this.getCurrentMonth();
    this.getBillingAccountIds();
    this.getOrganization();
    this.getUniqueProject();
    this.getUniqueServicesDescription();
  },
  methods: {
    selectBillingIdFromOrganization() {
      this.projectSelected = "";
      this.selectedBillingAccountId = "";
      this.getBillingAccountIds();
    },
    selectBillingId() {
      this.projectSelected = "";
      this.getBillingAccountIds();
    },
    getCurrentMonth() {
      // var date = new Date();
      // var currentMonthDate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 30);
      this.endDate = new Date();
      this.endDate.setDate(this.endDate.getDate());
      this.dateRange.push(this.startDate);
      this.dateRange.push(this.endDate);
    },
    filterDate() {
      this.isLoading = true;
      if (this.dateRange.length > 0) {
        this.startDate = this.dateRange[0];
        this.endDate = this.dateRange[1];
      }
      if (this.dateRange[0] == null) {
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate());
      }
      this.getUniqueServicesDescription();
      this.getUniqueProject();
      this.getBillingAccountIds();
    },
    clear() {
      this.isLoad = true;
      this.projectSelected = "";
      this.selectedBillingAccountId = "";
      this.selectedOrganization = null;
      this.dateRange = "";
      this.dateRange = [];
      this.getCurrentMonth();
      this.getUniqueProject();
      this.getBillingAccountIds();
      this.getOrganization();
      this.getUniqueServicesDescription();
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      let d = new Date(inputFormat),
        month = pad(d.getMonth() + 1),
        day = pad(d.getDate()),
        year = d.getFullYear();
      if (
        this.userData.default_region_id ==
        "0afb05a8-da40-41d9-9e51-e288e6db7789"
      ) {
        return [month, day, year].join("-");
      } else {
        return [day, month, year].join("-");
      }
    },
    convertDateForPassing(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      let d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "-"
      );
    },
    getUniqueServicesDescription() {
      this.loading = true;
      let formatedStartDate = this.convertDateForPassing(this.startDate);
      let formatedEndDate = this.convertDateForPassing(this.endDate);
      const paramObj = {
        global_admin_report:
          (this.userData.role_name == "GLOBAL_ADMIN" ||
            this.userData.role_name == "ACCOUNT_MANAGER") &&
          this.selectedBillingAccountId.length <= 0 &&
          this.selectedOrganization == null
            ? true
            : false,

        currency:
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? this.selectedCurrency
            : "USD",
      };

      if (
        this.selectedOrganization !== null &&
        this.selectedBillingAccountId.length <= 0
      ) {
        paramObj.selected_company_id = this.selectedOrganization.id;
      }

      if (this.selectedBillingAccountId.length > 0) {
        let ids = [];
        for (const id of this.selectedBillingAccountId) {
          ids.push(id.toString().split("_")[0]);
        }
        paramObj.selected_billing_account_id = ids.toString();
      }

      if (this.projectSelected.length > 0) {
        paramObj.project_names = this.projectSelected.toString();
      }

      axios
        .get(
          `/gcp-spend/daily/${this.userData.default_region_id}?start_date=${formatedStartDate}&end_date=${formatedEndDate}`,
          {
            params: paramObj,
          }
        )
        .then((response) => {
          this.loading = false;
          this.isLoading = false;
          this.isLoad = false;

          const date = new Date(this.startDate);
          const end = new Date(this.endDate);
          const dates = [];

          while (date <= end) {
            if (date == end) {
              dates.push(new Date(date));
              break;
            } else {
              dates.push(new Date(date));
              date.setDate(date.getDate() + 1);
            }
          }
          this.selectedDates = dates.map((v) => this.convertDate(v));
          this.$emit("getCurrentMonth", this.selectedDates);
          this.$emit("getUniqueServicesDescription", response);
          this.$emit("getCurrency", this.selectedCurrency);
          store.commit("app/CURRENCY_TYPE", this.selectedCurrency);
          this.$emit("loadingSpinner", this.loading);
        })
        .catch(() => {
          this.isLoading = false;
          this.isLoad = false;
          this.loading = false;
          this.$emit("loadingSpinner", this.loading);
        });
    },
    getUniqueProject() {
      this.loading = true;
      let formatedStartDate = this.convertDateForPassing(this.startDate);
      let formatedEndDate = this.convertDateForPassing(this.endDate);
      const paramObj = {
        global_admin_report:
          (this.userData.role_name == "GLOBAL_ADMIN" ||
            this.userData.role_name == "ACCOUNT_MANAGER") &&
          this.selectedBillingAccountId.length <= 0 &&
          this.selectedOrganization == null
            ? true
            : false,

        currency:
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? this.selectedCurrency
            : "USD",
      };

      if (
        this.selectedOrganization !== null &&
        this.selectedBillingAccountId.length <= 0
      ) {
        paramObj.selected_company_id = this.selectedOrganization.id;
      }

      if (this.selectedBillingAccountId.length > 0) {
        let ids = [];
        for (const id of this.selectedBillingAccountId) {
          ids.push(id.toString().split("_")[0]);
        }
        paramObj.selected_billing_account_id = ids.toString();
      }

      if (this.projectSelected.length > 0) {
        paramObj.project_names = this.projectSelected.toString();
      }

      axios
        .get(
          `/gcp-spend/by_projects/${this.userData.default_region_id}?start_date=${formatedStartDate}&end_date=${formatedEndDate}`,
          {
            params: paramObj,
          }
        )
        .then((response) => {
          this.$emit("getUniqueProject", response);
          this.$emit("getCurrency", this.selectedCurrency);
          this.$emit("loadingSpinner", this.loading);
        })
        .catch((error) => {
          this.$emit("loadingSpinner", this.loading);
          console.log(error);
        });
    },
    getBillingAccountIds() {
      this.noOptionMessage = "Sorry, data is loading";
      this.projectOptions = [];
      if (this.selectedBillingAccountId.length <= 0) {
        this.billingAccountIds = [];
      }
      const paramObj = {
        currency:
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? this.selectedCurrency
            : "USD",
      };

      if (
        this.selectedOrganization !== null &&
        this.selectedBillingAccountId.length <= 0
      ) {
        paramObj.selected_company_id = this.selectedOrganization.id;
      }

      if (this.selectedBillingAccountId.length > 0) {
        let ids = [];
        for (const id of this.selectedBillingAccountId) {
          ids.push(id.toString().split("_")[0]);
        }
        paramObj.selected_billing_account_id = ids.toString();
      }

      axios
        .get(`/gcp-spend/options/${this.userData.default_region_id}`, {
          params: paramObj,
        })
        .then((response) => {
          let projects = [];
          projects = response.data.project_names;
          if (projects.length == 0) {
            this.noOptionMessage = "Sorry, data is not avaialble";
          }

          projects.forEach((element) => {
            if (element !== null) {
              this.projectOptions.push(element);
            }
          });

          // var account_ids = [];

          // for (const [key, value] of Object.entries(
          //   response.data.raw_billing_account_ids
          // )) {
          //   billling_account_ids.push(value);
          // }

          // this.userData.gcp_billing_account_id = billling_account_ids;
          // localStorage.setItem("userData", JSON.stringify(this.userData));
          // store.commit("app/USER_DATA", this.userData);

          if (this.selectedBillingAccountId.length <= 0) {
            let account_ids = [];
            for (const [key, value] of Object.entries(
              response.data.billing_account_ids
            )) {
              if (value.billing_account_name == null) {
                account_ids.push(value.billing_account_id);
              } else {
                account_ids.push(
                  value.billing_account_id + "_" + value.billing_account_name
                );
              }
            }
            this.billingAccountIds = account_ids;
            this.$emit("billingAccountIds", account_ids);
          }
          this.$emit("loadingSpinner", this.loading);
        })
        .catch((error) => {
          this.$emit("loadingSpinner", this.loading);
          console.log(error);
        });
    },
    getOrganization() {
      if (this.userData.role_name == "GLOBAL_ADMIN") {
        axios
          .get(`/organizations/region/${this.userData.default_region_id}`)
          .then((response) => {
            this.organization = response.data.items;
            const orgs = [];
            for (const [key, value] of Object.entries(response.data.items)) {
              orgs.push(value.id);
            }
            this.userData.orgs_list = orgs;
            localStorage.setItem("userData", JSON.stringify(this.userData));
            store.commit("app/USER_DATA", this.userData);
            this.$emit("organization", response.data.items);
            this.$emit("loadingSpinner", this.loading);
          })
          .catch((error) => {
            this.$emit("loadingSpinner", this.loading);
            console.log(error);
          });
      } else if (this.userData.role_name == "ACCOUNT_MANAGER") {
        const paramObj = {
          region_id: this.userData.default_region_id,
        };

        axios
          .get(`/organizations/account_manager/${this.userData.id}`, {
            params: paramObj,
          })
          .then((response) => {
            this.organization = response.data;
            const orgs = [];
            for (const [key, value] of Object.entries(response.data)) {
              orgs.push(value.id);
            }
            this.userData.orgs_list = orgs;
            localStorage.setItem("userData", JSON.stringify(this.userData));
            store.commit("app/USER_DATA", this.userData);
            this.$emit("organization", response.data);
            this.$emit("loadingSpinner", this.loading);
          })
          .catch((error) => {
            this.$emit("loadingSpinner", this.loading);
            console.log(error);
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.custom-datepicker-filter {
  width: 100%;
}

.filter-body {
  .card-body {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}
</style>
<style>
.mx-input {
  height: 37px;
}
</style>
