<template>
  <b-card class="banner" :style="{ backgroundColor: backgroundColor }">
    <b-row class="banner-card">
      <b-col cols="12" lg="3" md="3" sm="3" class="align-items-center">
        <b-row>
          <b-col cols="12" lg="7" md="8" sm="9">
            <span class="announcement"
              ><strong>{{ title }}</strong></span
            >
          </b-col>
          <b-col cols="12" lg="5" md="4" sm="3"
            ><span class="arrow right"></span
          ></b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        :lg="routerUrl ? '6' : '8'"
        :md="routerUrl ? '6' : '8'"
        sm="7"
        class="pl-md-0 pr-md-0 pl-sm-0 pr-sm-0 d-flex align-items-center justify-content-center"
      >
        <span class="text pl-sm-0 pr-sm-0">{{ text }}</span>
      </b-col>
      <b-col
        cols="12"
        lg="2"
        md="2"
        sm="1"
        class="d-flex align-items-center pl-sm-0 pr-sm-0"
        v-if="routerUrl"
      >
        <b-button @click="renderToFeature" variant="primary" class="try-it">
          Try It
        </b-button>
      </b-col>

      <b-col cols="12" lg="1" md="1" sm="1" class="pl-sm-0">
        <span class="close-button">
          <feather-icon icon="XIcon" size="18" @click="closePop" />
        </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { title } from "@/@core/utils/filter";
import { BButton, BRow, BCol, BCard } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
  },
  props: {
    closeBanner: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    routerUrl: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
  },
  methods: {
    closePop() {
      this.$emit("close-banner-popup", true);
    },
    renderToFeature() {
      this.$router.push({ name: this.routerUrl });
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.banner {
  margin-bottom: 1.5rem;

  .card-body {
    padding: 0rem;
  }
}

.arrow {
  border: solid $white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 16px;
  margin-top: 0.45rem;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.text {
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.announcement {
  color: $white;
  padding: 1rem;
  display: flex;
  justify-content: start;
}
.banner-card {
  padding: 2px !important;
}
.close-button {
  display: flex;
  justify-content: end;
  color: $white;
  cursor: pointer;
  padding: 1rem;
}

.try-it {
  border-color: $white !important;
}
</style>
