<template>
  <b-card text-variant="center">
    <h4 class="d-flex text-align-justify">Latest GCP Incidents</h4>
    <hr />
    <loading
      :active.sync="loading"
      :can-cancel="true"
      :is-full-page="false"
      color="#4285f4"
      loader="dots"
    />
    <div
      v-for="i in pages"
      :key="i.id"
      @click="viewGcpIncident(i.id)"
      class="cursor-pointer"
      v-show="!loading"
    >
      <b-row>
        <b-col lg="9" md="9" sm="12">
          <p class="media-heading mb-0 font-weight-bolder gcp-text-justify">
            {{ i.title.slice(0, 40) }} -
            {{ i.modified.slice(0, 10) }}
          </p>
          <p class="gcp-text-justify">{{ i.summary.slice(0, 65) }}</p>
        </b-col>
        <b-col
          lg="2"
          md="2"
          sm="6"
          :class="
            i.severity === 'high'
              ? 'feather-danger'
              : i.severity === 'medium'
              ? 'feather-warning'
              : 'feather-info'
          "
          class="d-flex justify-content-center align-items-center gcp"
        >
          <div>
            {{ i.severity.toUpperCase() }}
          </div>
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="6"
          class="d-flex justify-content-center align-items-center gcp"
        >
          <feather-icon
            v-if="i.status === 'AVAILABLE'"
            icon="CheckCircleIcon"
            size="16"
            class="feather-success"
          />

          <feather-icon
            v-if="i.status === 'SERVICE_INFORMATION'"
            icon="InfoIcon"
            size="16"
            class="feather-info"
          />
          <feather-icon
            v-if="i.status === 'SERVICE_DISRUPTION'"
            icon="InfoIcon"
            size="16"
            class="feather-info"
          />
          <feather-icon
            v-if="i.status === 'SERVICE_OUTAGE'"
            icon="XCircleIcon"
            size="16"
            class="feather-danger"
          />
        </b-col>
      </b-row>
      <hr />
    </div>

    <b-row class="mt-1">
      <b-col class="d-flex justify-content-between">
        <b-row>
          <b-col class="col-auto">
            <feather-icon
              icon="CheckCircleIcon"
              size="16"
              class="feather-success"
            />
            <span class="legend"> Available </span>
          </b-col>
          <b-col class="col-auto">
            <feather-icon icon="InfoIcon" size="16" class="feather-info" />
            <span class="legend"> Service information</span>
          </b-col>
          <b-col class="col-auto">
            <feather-icon icon="InfoIcon" size="16" class="feather-warning" />
            <span class="legend"> Service disruption</span>
          </b-col>
          <b-col class="col-auto">
            <feather-icon icon="XCircleIcon" size="16" class="feather-danger" />
            <span class="legend"> Service outage</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-auto d-flex justify-content-end align-items-end">
        <b-link :to="{ name: 'dashboard-usa-incidents' }">Show More</b-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BLink,
  BRow,
  BCol,
} from "bootstrap-vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "firebase/compat/database";
import Firebase from "firebase/compat/app";

const database = Firebase.database().ref("/gcp_incidents");

export default {
  mounted() {
    database.child("modified").once("value", (pages) => {
      pages.forEach((page) => {
        this.pages.push({
          ref: page.ref,
          id: page.child("id").val(),
          title: page.child("title").val(),
          summary: page.child("summary").val(),
          modified: page.child("modified").val(),
          severity: page.child("severity").val(),
          status: page.child("most_recent_update/status").val(),
        });
      });
      this.pages.reverse();
    });
  },
  created() {
    const self = this;
    database
      .orderByChild("modified")
      .limitToLast(3)
      .on("child_removed", function (data) {
        const updatedPages = self.pages.filter((item) => item.id !== data.key);
        self.pages = updatedPages;
      });
    database
      .orderByChild("modified")
      .limitToLast(3)
      .on("child_added", function (data) {
        self.pages.push({
          ref: data.ref,
          id: data.child("id").val(),
          title: data.child("title").val(),
          summary: data.child("summary").val(),
          modified: data.child("modified").val(),
          severity: data.child("severity").val(),
          status: data.child("most_recent_update/status").val(),
        });
      });
    database
      .orderByChild("modified")
      .limitToLast(3)
      .on("child_changed", function (data) {
        const objIndex = self.pages.findIndex((obj) => obj.id == data.key);
        self.pages[objIndex].id = data.child("id").val();
        self.pages[objIndex].title = data.child("title").val();
        self.pages[objIndex].summary = data.child("summary").val();
        self.pages[objIndex].modified = data.child("modified").val();
        self.pages[objIndex].severity = data.child("severity").val();
        self.pages[objIndex].status = data
          .child("most_recent_update/status")
          .val();
      });
  },
  components: {
    Loading,
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BLink,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
      pages: [],
    };
  },
  methods: {
    viewGcpIncident(id) {
      window.open("https://status.cloud.google.com/incidents/" + id);
    },
  },
};
</script>

<style scoped>
hr {
  margin: 5px;
}
.legend {
  margin-left: 0px;
  margin-right: 0px;
  font-size: 0.9rem;
}
.gcp-text-justify {
  text-align: justify;
  margin: 0;
}
@media (max-width: 600px) {
  .gcp {
    width: 50%;
  }
}
</style>
