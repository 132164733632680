<template>
  <div>
    <b-card v-if="userData.account_manager">
      <b-row>
        <b-col cols="12" lg="10" md="9" sm="9">
          <h5 class="mb-1">My Account Manager</h5>
          <hr />
          <div class="d-flex flex-wrap">
            <div class="d-flex">
              <b-avatar size="40" variant="light-primary">
                <feather-icon icon="UserIcon" size="22" />
              </b-avatar>
            </div>
            <div class="manager-details">
              <span class="font-weight-bolder manager-info">Name - </span>
              {{
                userData.account_manager.display_name == null
                  ? "NA"
                  : userData.account_manager.display_name
              }}
            </div>

            <div class="manager-details">
              <span class="font-weight-bolder manager-info">Email - </span>
              {{
                userData.account_manager.email == null
                  ? "NA"
                  : userData.account_manager.email
              }}
            </div>

            <div class="manager-details">
              <span class="font-weight-bolder manager-info">Contact - </span>
              {{
                userData.account_manager.phone_number == null
                  ? "NA"
                  : userData.account_manager.phone_number
              }}
            </div>
          </div>
        </b-col>
        <b-col class="pt-2 pb-2 mr-2">
          <div class="d-flex justify-content-end">Any queries?</div>
          <div class="d-flex justify-content-end">Contact me.</div>
          <div class="d-flex justify-content-end">
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-1 ml-1"
            /><feather-icon icon="PhoneIcon" size="18" class="mr-1" />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
};
</script>

<style>
.manager-details {
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.manager-info {
  padding-right: 0.5rem;
}
#dashboard-usa > div:nth-child(1) > div > div > div > div {
  padding: 1rem;
}
</style>
